<style lang="scss" scoped>
.regards {
  display: none;
}
.report-box {
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
}
.report-box table td {
  padding: 5px;
  // vertical-align: top;
}

.report-box table tr.top table td {
  padding-bottom: 20px;
}

.report-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.report-box table tr.information table td {
  padding-bottom: 40px;
}

.report-box table tr.heading td {
  background: #eee;
  border: 2px solid #ddd;
  font-weight: bold;
}

.report-box table tr.details td {
  padding-bottom: 20px;
}

.report-box table tr.item td {
  border: 2px solid #eee;
}

.report-box table tr.item.last td {
  border-bottom: none;
}

.container-img {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
}

.container-img img {
  max-height: 400px;
  width: 100%;
  vertical-align: middle;
}

.container-img .content {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  padding: 10px;
}

.container-img .content-other {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 91.5%;
  padding: 10px;
}
.page-break {
  page-break-after: always;
}

@media only screen and (max-width: 600px) {
  .report-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .report-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}
</style>

<template>
  <div>
    <PopupSectionTaskGalleryEditNew
      v-if="isEnablePopup == 'gallery_section_task'"
      :list_section_task_checkpoint="sectionTask"
      v-on:submitPickImage="submitPickImage"
      v-on:exit="closeModal"
    />

    <PopupRevisiNotes
      v-on:exit="closeModalRevisi"
      :id_customreport="generate_report.id"
      v-if="isEnablePopup == 'popup-revisi'"
    />
    <div class="report-box">
      <table width="100%">
        <tr class="top">
          <td colspan="12">
            <table>
              <tr>
                <td class="title">
                  <img
                    src="https://www.tukangbersih.com/main/svg/logo.svg"
                    alt="https://www.tukangbersih.com/"
                    style="width: 100%; max-width: 200px"
                  />

                  <a
                    id="noPrint"
                    v-if="generate_report.status_report == 'publish'"
                    class="btn btn-sm btn-secondary rounded"
                    style="float: right"
                    @click="doPrint()"
                  >
                    <i class="ti ti-printer"></i> Print
                  </a>

                  <a
                    v-if="generate_report.status_report != 'publish'"
                    id="noPrint"
                    class="btn btn-sm btn-primary rounded"
                    style="float: right; margin-right: 5px"
                    @click="applyData('draft')"
                  >
                    <i class="ti ti-save-alt"></i> Save Draft
                  </a>

                  <span v-if="canPublish">
                    <a
                      id="noPrint"
                      class="btn btn-sm btn-success rounded"
                      style="float: right; margin-right: 5px"
                      @click="applyData('publish')"
                    >
                      <i class="ti ti-share"></i> Publish
                    </a>
                  </span>

                  <span
                    v-if="canRevisi && generate_report.status_report == 'draft'"
                  >
                    <a
                      id="noPrint"
                      class="btn btn-sm btn-warning rounded"
                      style="float: right; margin-right: 5px"
                      @click="modalRevisi()"
                    >
                      Revisi
                    </a>
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr class="information">
          <td colspan="6">
            <h4 class="font-weight-bold">PT Tukang Bersih Indonesia</h4>
            Grand Slipi Tower Lt. 37 Suite F-I Jl. Letjen S. Parman<br />
            Kav. 22-24 Slipi,Jakarta Barat 11480<br />
            Hotline &nbsp;: 021-5101 6000 <br />
            Email &nbsp;&nbsp;&nbsp;&nbsp;: customer.care@tukangbersih.com
          </td>

          <td colspan="6" align="right">
            <h4 class="font-weight-bold">{{ generate_report.client_name }}</h4>
            {{ generate_report.client_email }}<br />
            {{ generate_report.client_phonenumber }}<br />
            {{ generate_report.client_address }} <br />
          </td>
        </tr>
        <tr class="information" style="margin-bottom: 4%">
          <td colspan="6">
            <table style="width: 450px">
              <tr>
                <td colspan="3" style="margin: 0; padding: 0">
                  <h5 class="font-weight-bold">
                    Generate Report Contract Monthly
                  </h5>
                </td>
              </tr>
              <tr>
                <td style="padding: 0; margin: 0; width: 100px">
                  Printed Date
                </td>
                <td style="padding: 0; margin: 0">&nbsp;:&nbsp;</td>
                <td style="padding: 0; margin: 0">
                  {{ dateNow }}
                </td>
              </tr>
              <tr>
                <td style="padding: 0; margin: 0; width: 100px">Date Period</td>
                <td style="padding: 0; margin: 0">&nbsp;:&nbsp;</td>
                <td style="padding: 0; margin: 0">
                  {{ generate_report.date_period["start"] }} -
                  {{ generate_report.date_period["end"] }}
                </td>
              </tr>
              <tr>
                <td style="padding: 0; margin: 0; width: 100px">
                  Section Task
                </td>
                <td style="padding: 0; margin: 0">&nbsp;:&nbsp;</td>
                <td style="padding: 0; margin: 0">
                  <span
                    v-for="(item, index) in generate_report.section_room"
                    :key="index"
                  >
                    {{ item.area_name }}
                    <span
                      v-if="index == generate_report.section_room.length - 1"
                    ></span>
                    <span v-else>,</span>
                  </span>
                </td>
              </tr>
            </table>
          </td>

          <td colspan="6" style="text-align: right; vertical-align: initial">
            <span class="font-weight-bold">Contract Number</span> #:
            {{ generate_report.contract_number }}<br />
            Project Description #:
            {{ generate_report.project_desc }}<br />
            Contract Start Date: {{ generate_report.contract_start_date }}<br />
            Contract End Date: {{ generate_report.contract_end_date }}
          </td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr>
          <td colspan="12">
            <h5 class="font-weight-bold" style="text-align: center">
              Executive Summary
            </h5>
            Dear Valued Customer, <br />
            Terima kasih atas kepercayaan Anda menggunakan jasa housekeeping
            service PT. Tukang Bersih Indonesia. Kami selalu berusaha untuk
            mengutamakan kepuasan pelanggan dan mengedepankan perkembangan di
            era digital dengan langkah mendukung teknologi ramah lingkungan
            (paperless system). <br />
            Sebagai komitmen PT. Tukang Bersih Indonesia kepada pelanggan untuk
            memberikan laporan atas pekerjaan yang sudah di lakukan dengan
            akurat, maka kami menggunakan sistem berbasis Android sebagai
            penunjang operation untuk melaporkan absensi dan progress pekerjaan
            setiap hari dari area.
          </td>
        </tr>
        <tr>
          <td colspan="12">Dengan ini kami melaporkan sebagai berikut:</td>
        </tr>
        <tr>
          <td colspan="12">
            <mc-wysiwyg
              v-model="html_notes"
              :hide="hide"
              height="auto"
            ></mc-wysiwyg>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            Demikian laporan ini dibuat sebagai bukti pekerjaan dan service
            kepada pelanggan. Kami bangga dan senang melayani dan berharap terus
            bisa mendukung Anda yang telah memberikan kepercayaan kepada
            kami.<br /><br />
            Terima kasih. <br /><br /><br />
            Hormat kami, <br />
            <span class="regards">{{ regards }}</span
            ><br />
            <input
              type="text"
              style="border: none"
              v-model="regards"
              placeholder="(…………………….)"
            />
          </td>
        </tr>
      </table>
      <div class="page-break"></div>
      <table width="100%">
        <tr>
          <td></td>
        </tr>
        <tr>
          <td colspan="12" style="text-align: center">
            <h5 class="font-weight-bold">Quantitative Report 1 (Absensi)</h5>
          </td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr class="heading">
          <td
            rowspan="3"
            width="100"
            style="vertical-align: middle; text-align: center"
          >
            Year
          </td>
          <td
            rowspan="3"
            width="100"
            style="vertical-align: middle; text-align: center"
          >
            Month
          </td>
          <td colspan="8" align="center">Shift</td>
          <td colspan="2" align="center">Attendance</td>
        </tr>
        <tr class="heading">
          <td colspan="2" style="vertical-align: middle; text-align: center">
            Pagi
          </td>
          <td colspan="2" style="vertical-align: middle; text-align: center">
            Siang
          </td>
          <td colspan="2" style="vertical-align: middle; text-align: center">
            Malam
          </td>
          <td colspan="2" style="vertical-align: middle; text-align: center">
            Absent
          </td>
          <td rowspan="2" style="vertical-align: middle; text-align: center">
            All
          </td>
          <td rowspan="2" style="vertical-align: middle; text-align: center">
            %
          </td>
        </tr>
        <tr class="heading">
          <td style="vertical-align: middle; text-align: center">All</td>
          <td style="vertical-align: middle; text-align: center">%</td>
          <td style="vertical-align: middle; text-align: center">All</td>
          <td style="vertical-align: middle; text-align: center">%</td>
          <td style="vertical-align: middle; text-align: center">All</td>
          <td style="vertical-align: middle; text-align: center">%</td>
          <td style="vertical-align: middle; text-align: center">All</td>
          <td style="vertical-align: middle; text-align: center">%</td>
        </tr>
        <tr
          class="item"
          v-for="(item, keyQabsen) in generate_report.list_quantitative_absen"
          :key="keyQabsen"
        >
          <td style="vertical-align: middle; text-align: center">
            {{ item.year }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.month }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.shift_pagi }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            <span v-if="item.shift_pagi_percent">
              {{ item.shift_pagi_percent }}</span
            >
            <span v-else>0</span>
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.shift_siang }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            <span v-if="item.shift_siang_percent">
              {{ item.shift_siang_percent }}</span
            >
            <span v-else>0</span>
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.shift_malam }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            <span v-if="item.shift_malam_percent">
              {{ item.shift_malam_percent }}</span
            >
            <span v-else>0</span>
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.shift_absent }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            <span v-if="item.shift_absent_percent">
              {{ item.shift_absent_percent }}</span
            >
            <span v-else>0</span>
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.all_attd }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.all_attd_percent }}
          </td>
        </tr>
      </table>
      <div class="page-break"></div>
      <table width="100%">
        <tr>
          <td></td>
        </tr>
        <tr>
          <td colspan="12" style="text-align: center">
            <h5 class="font-weight-bold">
              Quantitative Report 2 (Scan Barcode)
            </h5>
          </td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr class="heading">
          <td rowspan="2" style="vertical-align: middle; text-align: center">
            Year
          </td>
          <td rowspan="2" style="vertical-align: middle; text-align: center">
            Month
          </td>
          <td rowspan="2" style="vertical-align: middle; text-align: center">
            Total Scan <br />Barcode
          </td>
          <td colspan="10" style="vertical-align: middle; text-align: center">
            Area
          </td>
        </tr>
        <tr class="heading">
          <td style="vertical-align: middle; text-align: center">Section</td>
          <td style="vertical-align: middle; text-align: center">Type</td>
          <td style="vertical-align: middle; text-align: center">Floor</td>
          <td style="vertical-align: middle; text-align: center">Name</td>
          <td colspan="5" style="vertical-align: middle; text-align: center">
            Area Task
          </td>
        </tr>
        <tr
          class="item"
          v-for="(
            item, keyQSbarcode
          ) in generate_report.list_quantitative_scan_barcode"
          :key="keyQSbarcode"
        >
          <td style="vertical-align: middle; text-align: center">
            {{ item.year }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.month }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.total_scan_barcode }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.section }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.area_type }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.area_floor }}
          </td>
          <td style="vertical-align: middle; text-align: center">
            {{ item.area_name }}
          </td>
          <td colspan="5" style="vertical-align: middle; text-align: left">
            {{ item.area_task }}
          </td>
        </tr>
      </table>
      <div class="page-break"></div>
      <table width="100%">
        <tr>
          <td colspan="12" width="100%">
            <div class="row" style="margin-top: 10px">
              <div class="col-md-12 text-center">
                <h5 class="font-weight-bold">Progress Documentation</h5>
              </div>
              <div class="col-md-4 text-center">
                <h6 class="font-weight-bold mb-2">BEFORE</h6>
                <div class="row">
                  <div
                    class="col-md-12 card mt-2 mb-2"
                    v-for="(item, keyImgBef) in generate_report.images_before"
                    :key="keyImgBef"
                    :class="{
                      'force-page-break': (keyImgBef + 1) % 3 === 0,
                      'print-margin': (keyImgBef + 1) % 3 === 0,
                    }"
                  >
                    <img
                      :src="item.photo"
                      :alt="item.notes"
                      class="card-img-top"
                      style="height: 350px; width: auto; object-fit: contain"
                    />

                    <a
                      v-if="
                        generate_report.status_report == 'draft' ||
                        generate_report.status_report == 'revisi'
                      "
                      class="btn btn-sm btn-info"
                      style="
                        border-radius: 2.25rem !important;
                        position: absolute;
                        right: 4px;
                        top: -14px;
                      "
                      @click="editImage(item.id, 'before')"
                      ><i class="ti ti-pencil-alt text-light"></i
                    ></a>

                    <div
                      style="
                        position: absolute;
                        top: 14px;
                        background-color: rgba(34, 32, 32, 0.3);
                        padding: 6px;
                        color: #fff;
                      "
                    >
                      Before
                    </div>

                    <div
                      class="card-body"
                      style="height: 55px; line-height: 15px"
                    >
                      {{ item.notes }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-center">
                <h6 class="font-weight-bold mb-2">AFTER</h6>
                <div class="row">
                  <div
                    class="col-md-12 card mt-2 mb-2"
                    v-for="(item, keyImgAft) in generate_report.images_after"
                    :key="keyImgAft"
                    :class="{
                      'force-page-break': (keyImgAft + 1) % 3 === 0,
                      'print-margin': (keyImgAft + 1) % 3 === 0,
                    }"
                  >
                    <img
                      :src="item.photo"
                      :alt="item.notes"
                      class="card-img-top"
                      style="height: 350px; width: auto; object-fit: contain"
                    />
                    <a
                      v-if="
                        generate_report.status_report == 'draft' ||
                        generate_report.status_report == 'revisi'
                      "
                      class="btn btn-sm btn-info"
                      style="
                        border-radius: 2.25rem !important;
                        position: absolute;
                        right: 4px;
                        top: -14px;
                      "
                      @click="editImage(item.id, 'after')"
                      ><i class="ti ti-pencil-alt text-light"></i
                    ></a>
                    <div
                      style="
                        position: absolute;
                        top: 14px;
                        background-color: rgba(34, 32, 32, 0.3);
                        padding: 6px;
                        color: #fff;
                      "
                    >
                      After
                    </div>

                    <div
                      class="card-body"
                      style="height: 55px; line-height: 15px"
                    >
                      {{ item.notes }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-center">
                <h6 class="font-weight-bold mb-2">OTHER / HIGHLIGHT</h6>
                <div class="row">
                  <div
                    class="col-md-12 card mt-2 mb-2"
                    v-for="(item, keyImgOth) in generate_report.images_other"
                    :key="keyImgOth"
                    :class="{
                      'force-page-break': (keyImgOth + 1) % 3 === 0,
                      'print-margin': (keyImgOth + 1) % 3 === 0,
                    }"
                  >
                    <img
                      :src="item.photo"
                      :alt="item.notes"
                      class="card-img-top"
                      style="height: 350px; width: auto; object-fit: contain"
                    />

                    <a
                      v-if="
                        generate_report.status_report == 'draft' ||
                        generate_report.status_report == 'revisi'
                      "
                      class="btn btn-sm btn-info"
                      style="
                        border-radius: 2.25rem !important;
                        position: absolute;
                        right: 4px;
                        top: -14px;
                      "
                      @click="editImage(item.id, 'other')"
                      ><i class="ti ti-pencil-alt text-light"></i
                    ></a>

                    <div
                      style="
                        position: absolute;
                        top: 14px;
                        background-color: rgba(34, 32, 32, 0.3);
                        padding: 6px;
                        color: #fff;
                      "
                    >
                      Other
                    </div>
                    <div
                      class="card-body"
                      style="height: 55px; line-height: 15px"
                    >
                      {{ item.notes }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { McWysiwyg } from "@mycure/vue-wysiwyg";
import { McWysiwyg as Mc2Wysiwyg } from "@mycure/vue-wysiwyg";
import { ConcatSource } from "webpack-sources";
import PopupRevisiNotes from "./@PopupRevisiNotes.vue";
import axios from "axios";
import PopupSectionTaskGalleryEditNew from "./@PopupSectionTaskGalleryEditNew.vue";
export default {
  components: {
    McWysiwyg,
    Mc2Wysiwyg,
    PopupRevisiNotes,
    PopupSectionTaskGalleryEditNew,
  },
  props: ["generate_report"],

  data() {
    return {
      isEnablePopup: false,
      canPublish: false,
      canRevisi: false,
      dateNow: new Date().toLocaleDateString(),
      meta_url: "report/generate",
      html_notes:
        "<div>Kerusakan / Perbaikan :</div><div>1.&nbsp;</div><div>2.&nbsp;</div><div><br></div><div>Saran :</div><div>1.</div><div>2.</div>",
      regards: null,
      typeImgSelected: null,
      sectionTask: [],
      hide: {
        strikethrough: true,
        table: true,
        underline: true,
        heading: true,
        bold: true,
        italic: true,
        // alignLeft: true,
        // alignCenter: true,
        // alignRight: true,
        ol: true,
        ul: true,
        url: true,
        indent: true,
        outdent: true,
      },
      testiOne: [],
    };
  },

  computed: {
    ...mapState("crud", {
      msg_error: (state) => state.error,
    }),
  },
  created() {
    this.html_notes = this.generate_report.notes
      ? this.generate_report.notes
      : this.html_notes;

    this.regards = this.generate_report.regards
      ? this.generate_report.regards
      : this.regards;

    if (
      localStorage.getItem("userIdPrivileges") == 10 ||
      localStorage.getItem("userIdPrivileges") == 18
    ) {
      this.canPublish = true;
    }

    if (
      localStorage.getItem("userIdPrivileges") == 10 ||
      localStorage.getItem("userIdPrivileges") == 18
    ) {
      this.canRevisi = true;
    }
  },
  methods: {
    applyData(value) {
      let params = {
        id: this.generate_report.id,
        notes: this.html_notes,
        regards: this.regards,
        status: value,
      };
      // console.log(params);
      this.$emit("applyData", params);
    },
    doPrint() {
      this.$htmlToPaper("printView");
    },
    modalRevisi() {
      this.isEnablePopup = "popup-revisi";
    },
    closeModalRevisi() {
      this.isEnablePopup = false;
    },
    closeModal() {
      this.isEnablePopup = false;
    },
    async editImage(id, type) {
      console.log(id, type);
      this.sectionTask = [];
      // console.log(this.generate_report);
      const idCustomReportDetailCleaningImage = id;
      const startDatePeriod = this.generate_report.params.date_period.start;
      const endDatePeriod = this.generate_report.params.date_period.end;
      const contractId = this.generate_report.params.id_contract;
      const idSectionRoom = this.generate_report.params.id_section_room;
      this.typeImgSelected = type;
      await axios
        .post("/report/edit-detail-contract-section-taks", {
          id_contract: contractId,
          id_section_room: idSectionRoom,
          start_date: startDatePeriod,
          end_date: endDatePeriod,
        })
        .then((res) => {
          let response = res.data;
          if (response.status) {
            for (let key in response.result) {
              let checkpoint = JSON.parse(response.result[key].checkpoint);
              for (let keyCheckPoint in checkpoint) {
                this.sectionTask.push({
                  id: idCustomReportDetailCleaningImage,
                  time: checkpoint[keyCheckPoint].time,
                  idqr: checkpoint[keyCheckPoint].idqr,
                  photo:
                    "https://astation.tukangbersih.com/" +
                    checkpoint[keyCheckPoint].photo,
                  notes: checkpoint[keyCheckPoint].notes,
                  type: checkpoint[keyCheckPoint].type,
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.statusText);
        });

      if (this.sectionTask.length > 0) {
        this.isEnablePopup = "gallery_section_task";
      }
    },
    async submitPickImage(item) {
      this.$emit("pickImage", item, this.typeImgSelected);
    },
  },
};
</script>
